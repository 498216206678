import clsx from "clsx";

export function Paginator({ pages, callback }: { pages: any; callback: any }) {
  let pageList = pages?.links ? pages.links : [];

  return (
    <div className="d-flex flex-stack flex-wrap p-10 hide-on-print">
      <ul className="pagination">
        {pageList.map((link: any) => {
          return (
            <li
              className={clsx("page-item", {
                previous: link.label === "&laquo; Previous",
                next: link.label === "Next &raquo;",
                active: link.active,
              })}
              key={link.label}
            >
              <a
                href="#"
                className="page-link"
                onClick={(e: any) => {
                  e.preventDefault();
                  callback(link?.url.split("page=")[1]);
                }}
              >
                {link.label === "Next &raquo;" ||
                link.label === "&laquo; Previous"
                  ? null
                  : link.label}
                {link.label === "Next &raquo;" ? (
                  <i className="next"></i>
                ) : null}
                {link.label === "&laquo; Previous" ? (
                  <i className="previous"></i>
                ) : null}
              </a>
            </li>
          );
        })}
      </ul>
      {false ? (
        <div className="fs-6 fw-bold text-gray-700">
          Showing {pages.from} to {pages.to} of {pages.total} entries
        </div>
      ) : null}
    </div>
  );
}

export const normalizePagination = (
  results: any,
  setter: any,
  setPaginatorData: any,
  setCount?: (count: number | string) => void
) => {
  if (results.links) {
    let { data, ...rest } = results;
    setter(data);
    setCount && setCount(rest.total);
    setPaginatorData(rest);
  } else {
    setter(results);
  }
};
