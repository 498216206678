/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/Errors/ErrorsPage";
import { Logout, AuthPage, useAuth } from "../modules/Auth";
import { App } from "../App";
import { ProtectedRoutes, UnProtectedRoutes } from "./ProtectRoutes";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./patch-styles.css";
import { QboAuthenticate } from "../modules/Errors/components/qbo-authenticate";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
// const { PUBLIC_URL } = process.env; COMMENTED OUT FOR THE SAKE OF CLEAN URLS
// <BrowserRouter basename={PUBLIC_URL}> was supposed to be the actual usage

const AppRoutes: FC = () => {
  // const { currentUser } = useAuth();

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route element={<ProtectedRoutes />}>
            <Route path="/*" element={<PrivateRoutes />} />
            <Route index element={<Navigate to="/dashboard" />} />
          </Route>
          <Route element={<UnProtectedRoutes />}>
            <Route path="auth/*" element={<AuthPage />} />
            <Route path="*" element={<Navigate to="/auth" />} />
          </Route>
          <Route path="qbo/authenticate" element={<QboAuthenticate />} />
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
