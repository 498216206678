export const removeCharacter = (character: any, text: string) => {
  /**
   * character could be _,$,% < >
   * string is your text to be removed some un necessary strings
   */
  if (!text) return text;
  return text.replaceAll(character, " ");
};

export const camelCaseWithSpace = (text: string) => {
  let words = text.split(" ");
  return words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
};

export function getFirstWord(input: string): string | null {
  if (!input && !input.trim()) return null;

  //Split the input string by whitespace
  const words = input.split(/\s+/);
  if (!words.length) return null;
  //return the first wod index 0
  return words[0];
}

export function getLastWord(input: string): string | null {
  if (!input && !input.trim()) return null;

  //Split the input string by whitespace
  const words = input.split(/\s+/);
  if (!words.length) return null;
  //return the first wod index 0
  return words[words.length - 1];
}

export function textToSlug(input: string = ""): string {
  return input.replace(/\s+/g, "-").toLowerCase();
}
