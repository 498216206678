export const SearchUtils = (
  searchList: URLSearchParams,
  setSearchList: any,
  URLMapper?: any
) => {
  const handleQueryChange = (name: string, value: any) => {
    // searchList.set("records_per_page", "1");
    searchList.set("page", "1");

    if (!value) {
      searchList.delete(URLMapper ? URLMapper[name] : name);
    } else {
      searchList.set(URLMapper ? URLMapper[name] : name, value);
    }
  };
  const updateQueryString = () => {
    setSearchList(searchList);
  };
  const progressPage = async (page: any) => {
    searchList.set("page", page);
    setSearchList(searchList);
  };

  function replaceParams(url: string, obj: Record<string, string>): string {
    const urlParams = new URLSearchParams(url);

    obj &&
      Object.entries(obj).forEach(([key, value]) => {
        if (urlParams.has(value)) {
          const paramValue = urlParams.get(value);
          urlParams.delete(value);
          urlParams.set(key, paramValue || "");
        }
      });

    return urlParams.toString();
  }

  function getKeyByValue<T>(
    object: Record<string, T>,
    value: T
  ): string | null {
    return (
      Object.entries(object).find(([key, val]) => val === value)?.[0] || null
    );
  }

  return {
    handleQueryChange,
    updateQueryString,
    progressPage,
    replaceParams,
    getKeyByValue,
  };
};
