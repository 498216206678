import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../modules/Auth";
import { LAST_VISITED } from "../modules/Profile/components/constants";

export const ProtectedRoutes = () => {
  const { currentUser } = useAuth();
  const location = useLocation();
  assignLastPage();

  return currentUser ? (
    <Outlet />
  ) : (
    <Navigate to="/auth" replace state={{ from: location }} />
  );
};

export const UnProtectedRoutes = () => {
  const { currentUser } = useAuth();
  assignLastPage();

  return !currentUser ? <Outlet /> : <Navigate to="/dashboard" />;
};

const assignLastPage = () => {
  const lastVisitedPage = window.location.pathname;
  // alert(lastVisitedPage);
  if (
    !["/auth", "/error/404", "/login", "/auth/login"].includes(lastVisitedPage)
  ) {
    localStorage.setItem(LAST_VISITED, lastVisitedPage);
  }
};
