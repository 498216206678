export const HorizontalApart = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  return <div className="d-flex justify-content-between">{children}</div>;
};

export const HorizontalAlign = ({
  children,
  marginTop,
}: {
  children: JSX.Element[] | JSX.Element;
  marginTop?: string;
}) => {
  return (
    <div
      className={`d-flex align-items-center ${marginTop ? marginTop : "mt-2"}`}
    >
      {children}
    </div>
  );
};
