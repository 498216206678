import { httpGet, httpPost } from "../modules/Auth/core/_requests";

export class SettingsApi {
  private viewUrl: string = "settings/view";
  private updateUrl: string = "settings/update";

  fetchGeneralSettings = async () => {
    //fetching the general settings
    let results = await httpGet(this.viewUrl);
    return this.exportSettings(results);
  };

  updateSettings = async (data: any) => {
    let results = await httpPost(this.updateUrl, { settings: data });
    console.log(results);
    return this.exportSettings(results);
  };

  exportSettings(results: any) {
    if (results.data.status) {
      return results.data.data;
    } else {
      return false;
    }
  }
}
