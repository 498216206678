import {
  put,
  call,
  fork,
  takeLatest,
  select,
  delay,
  take,
} from "redux-saga/effects";
import {
  doneSettingFetching,
  startSettingFetching,
  // cancelSettingUpdate,
  failedSettingFetching,
  startSettingUpdate,
  cancelSettingUpdate,
  successSettingUpdate,
} from "./index";
import { SettingsApi } from "../../apis/settingsApi";
import { getEditedSettings } from "../helpers/selectors";

const API = new SettingsApi();

function* watchFetching() {
  yield takeLatest(startSettingFetching.type, handleFetching);
  // yield fork(handleFetching);
}

function* watchUpdatingSetting() {
  yield takeLatest(startSettingUpdate.type, updateSetting);
}

function* handleFetching() {
  try {
    const response: ReturnType<typeof API.fetchGeneralSettings> = yield call(
      API.fetchGeneralSettings
    );
    yield put(doneSettingFetching(response));
  } catch (error) {
    yield put(failedSettingFetching("An error has occured, pole!!"));
  } finally {
  }
}

//now lets update the setting
function* updateSetting(action: any) {
  try {
    const updated: ReturnType<typeof getEditedSettings> = yield select(
      getEditedSettings
    );
    const { wait, error, ...rest } = updated[action.payload.key];
    const responseData: ReturnType<typeof API.updateSettings> =
      yield API.updateSettings(rest);
    yield put(
      successSettingUpdate({
        key: action.payload.key,
        msg: "Successfully updated settings",
      })
    );
    yield delay(1000);
    yield put(doneSettingFetching(responseData));
    yield put(
      cancelSettingUpdate({
        key: action.payload.key,
      })
    );
    // console.log(responseData);
  } catch (error) {}
}

const watchingFetching = [fork(watchFetching), fork(watchUpdatingSetting)];
export default watchingFetching;
