import { error } from "console";
import {
  ActionType,
  RequestActionDoneFetching,
  RequestActionFetching,
  RequestActions,
  SET_LOADING_STATUS_WORKFLOW_TYPE,
  SET_WORKFLOW_TYPE,
} from "./actions";
import { initialState } from "./request-view-initialstate";

//basically we start with initial state THE REQUEST ITSELF

export const RequestReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case RequestActions.SET_REQUEST_DATA:
      return {
        ...state,
        request: action.payload,
      };
    case RequestActions.SET_REQUEST_SUBSCRIPTION_DATA:
      return {
        ...state,
        subscription: action.payload,
      };
    case RequestActionFetching.type:
      return {
        ...state,
        isLoading: action.payload,
      };

    case RequestActionDoneFetching.type:
      return {
        ...state,
        isLoading: action.payload,
      };
    case RequestActions.SET_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_LOADING_STATUS_WORKFLOW_TYPE:
      return {
        ...state,
        isLoadingWorkflowType: action.payload,
      };

    case SET_WORKFLOW_TYPE:
      return {
        ...state,
        workflowType: action.payload,
      };
    default:
      return state;
  }
};
