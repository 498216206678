import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { SidebarMenuItemWithSub } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItemWithSub";
import { SidebarMenuItem } from "../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItem";

import { lazy, Suspense } from "react";
import { SimpleLoader } from "../../general-components/loaders/simple-loader";
import { Tabs } from "../../general-components/tabs";
import { APP_NAME } from "../../../_metronic/mis.themes";
const innerRouteBreadCrumbs: Array<PageLink> = [
  {
    title: "Settings",
    path: "/settings/general",
    isSeparator: false,
    isActive: false,
  },

  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const SettingsPage = () => {
  document.title = `Settings | ${APP_NAME}`;
  const VfdSettings = lazy(() => import("./components/vfd-settings"));
  const StateUsers = lazy(
    () => import("./components/workflows/state-users/state.users.index")
  );

  const ExpenseCategory = lazy(
    () => import("./components/expense-category/expenses-category")
  );
  const TriggersIndex = lazy(
    () => import("./components/triggers/triggers.index")
  );
  const QuickBookIntegration = lazy(
    () => import("./components/quickbooks-integration")
  );
  const FixedAssetKlass = lazy(() => import("./components/fixed-assets-klass"));
  // const Industries = lazy(() => import("./components/settings-crm/Industries"));
  const ChurnReasons = lazy(
    () => import("./components/settings-subscriptions/ChurnReasons")
  );
  const TicketTypes = lazy(
    () => import("./components/settings-customer-services/ticket-types")
  );
  // const ProblemsAndResolutions = lazy(
  //   () =>
  //     import("./components/settings-customer-services/probles-and-resolutions")
  // );

  const WLPTemplates = lazy(
    () =>
      import("../UnitsProviders/components/wlp-templates-libs/wlp-templates")
  );
  const KpiList = lazy(
    () => import("./components/settings-customer-services/kpi-list")
  );
  const SourcesList = lazy(
    () => import("./components/settings-sales-management/sources-list")
  );
  const Resources = lazy(
    () => import("./components/settings-order-fulfillment/resources")
  );
  const Providers = lazy(
    () =>
      import(
        "./components/settings-units-and-providers/providers-libs/providers"
      )
  );
  const WorkflowTypes = lazy(
    () => import("./components/workflows/types/type.index")
  );

  const PresetsManager2 = lazy(
    () => import("./components/settings-units-and-providers/presets-manager")
  );
  const PresetsManager = lazy(
    () => import("../UnitsProviders/components/presets/preset-manager")
  );

  const UsersSettingsEdit = lazy(
    () => import("./components/users/users-settings-edit")
  );
  const IndustriesSettings = lazy(
    () => import("./components/settings-crm/industries-settings")
  );
  const PermissionSettings = lazy(
    () => import("./components/permissions-settings")
  );
  const PermissionRolesSettings = lazy(
    () => import("./components/permissions-on-roles-settings")
  );

  const PermissionRolesSettingsDuplicate = lazy(
    () => import("./components/permissions-on-roles-settings-duplicate")
  );

  const NotificationSettings = lazy(
    () => import("./components/settings-notifications")
  );
  // const WLPTemplates = lazy(
  //   () => import("./components/wlp-templates-libs/wlp-templates")
  // );

  const LocationSettings = lazy(
    () => import("./components/locations/locations")
  );
  const ProductCategory = lazy(() => import("./components/product-category"));

  const GeneralSettings = lazy(() => import("./components/settings-general"));
  const CompanySettings = lazy(() => import("./components/settings-company"));
  const CaseTypes = lazy(() => import("./components/case-types"));
  const UsersSettings = lazy(() => import("./components/users/users-settings"));
  const UsersSettingsNew = lazy(
    () => import("./components/users/users-settings-new")
  );
  const RolesSettings = lazy(() => import("./components/roles/roles-settings"));
  const WorkingHours = lazy(() => import("./components/working-hours"));
  const Holidays = lazy(() => import("./components/holidays"));
  const CurrencySetting = lazy(
    () => import("./components/currency/currency-settings")
  );
  const TaxRates = lazy(() => import("./components/tax-rates"));
  const InvoiceSigningMethod = lazy(
    () => import("./components/settings-signing-method")
  );
  const AccountingControls = lazy(
    () => import("./components/settings-account-controls")
  );
  const Departments = lazy(
    () => import("./components/settings-departments/department.index")
  );
  const WorkflowTags = lazy(() => import("./components/settings-tags/tags"));
  const WorkflowState = lazy(
    () => import("./components/workflows/states/state.index")
  );
  const WorkflowKPI = lazy(
    () => import("./components/workflows/kpi/kpi.index")
  );
  const StateFunction = lazy(
    () => import("./components/workflows/state-functions/state.function.index")
  );

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="general"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>General</PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <Tabs
                  module="Settings"
                  title="General"
                  contents={[
                    {
                      hook: "general",
                      name: "General",
                      component: GeneralSettings,
                    },
                    {
                      hook: "company",
                      name: "Company",
                      component: CompanySettings,
                    },
                    {
                      hook: "notifications",
                      name: "Notifications",
                      component: NotificationSettings,
                    },
                  ]}
                />
              </Suspense>
            </>
          }
        />

        <Route
          path="case-types"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Case Types
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <CaseTypes />
              </Suspense>
            </>
          }
        />

        <Route
          path="locations"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Product Locations
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <LocationSettings />
              </Suspense>
            </>
          }
        />

        <Route
          path="product-categories"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Product Categories
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <ProductCategory />
              </Suspense>
            </>
          }
        />
        <Route
          path="expense-categories"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Expense Categories
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <ExpenseCategory />
              </Suspense>
            </>
          }
        />

        <Route
          path="users"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>Users</PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <UsersSettings />
              </Suspense>
            </>
          }
        />

        <Route
          path="users/new"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Users Settings/New
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <UsersSettingsNew />
              </Suspense>
            </>
          }
        />
        <Route
          path="users/update/:id"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Users Settings Update
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <UsersSettingsEdit />
              </Suspense>
            </>
          }
        />
        {/* <Route
          path="permissions-settings"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Permissions Settings
              </PageTitle>
              <PermissionSettings />
            </>
          }
        /> */}
        <Route
          path="roles"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Roles Settings
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <RolesSettings />
              </Suspense>
            </>
          }
        />

        <Route
          path="roles/update/:id"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Roles Update
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <PermissionRolesSettings />
              </Suspense>
            </>
          }
        />
        <Route
          path="roles/duplicate/:id"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Roles Duplicate
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <PermissionRolesSettingsDuplicate />
              </Suspense>
            </>
          }
        />

        <Route
          path="currency"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Currency Settings
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <CurrencySetting />
              </Suspense>
            </>
          }
        />

        <Route
          path="working-hours"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Working Hours
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <WorkingHours />
              </Suspense>
            </>
          }
        />

        <Route
          path="holidays"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Holidays
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <Holidays />
              </Suspense>
            </>
          }
        />

        <Route
          path="quickbooks-integration"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                QuickBookIntegration
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <QuickBookIntegration />
              </Suspense>
            </>
          }
        />
        <Route
          path="churn-reasons"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Churn Reasons
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <ChurnReasons />
              </Suspense>
            </>
          }
        />
        <Route
          path="crm"
          element={
            <>
              <Suspense fallback={<SimpleLoader />}>
                <Tabs
                  title="CRM"
                  module="Settings"
                  contents={[
                    {
                      hook: "industries",
                      name: "Industries",
                      component: IndustriesSettings,
                    },
                    {
                      hook: "sources",
                      name: "Sources",
                      component: SourcesList,
                    },
                  ]}
                />
              </Suspense>
            </>
          }
        />
        <Route
          path="inventory"
          element={
            <>
              <Suspense fallback={<SimpleLoader />}>
                <Tabs
                  title="Inventory"
                  module="Settings"
                  contents={[
                    {
                      hook: "locations",
                      name: "Locations",
                      component: LocationSettings,
                    },
                    {
                      hook: "productcategories",
                      name: "Product Categories",
                      component: ProductCategory,
                    },
                    {
                      hook: "fixedassetclass",
                      name: "Fixed Asset Classes",
                      component: FixedAssetKlass,
                    },
                  ]}
                />
              </Suspense>
            </>
          }
        />
        <Route
          path="workflow"
          element={
            <>
              <Suspense fallback={<SimpleLoader />}>
                <Tabs
                  module="Settings"
                  title="Workflow"
                  contents={[
                    {
                      hook: "workflowtypes",
                      name: "Types",
                      component: WorkflowTypes,
                    },
                    {
                      hook: "departments",
                      name: "Deparments",
                      component: Departments,
                    },
                    {
                      hook: "workflowtags",
                      name: "Tags",
                      component: WorkflowTags,
                    },
                    {
                      hook: "workflow-state",
                      name: "States",
                      component: WorkflowState,
                    },
                    {
                      hook: "workflow-kpi",
                      name: "KPI",
                      component: WorkflowKPI,
                    },
                    {
                      hook: "workinghours",
                      name: "Working Hours",
                      component: WorkingHours,
                    },
                    {
                      hook: "holidays",
                      name: "Holidays",
                      component: Holidays,
                    },
                    {
                      hook: "state-users",
                      name: "State Users",
                      component: StateUsers,
                    },

                    {
                      hook: "state-functions",
                      name: "State Functions",
                      component: StateFunction,
                    },
                    // {
                    //   hook: "problemresolution",
                    //   name: "Problem & Resolutions",
                    //   component: ProblemsAndResolutions,
                    // },
                  ]}
                />
              </Suspense>
            </>
          }
        />
        <Route
          path="finance"
          element={
            <>
              <Suspense fallback={<SimpleLoader />}>
                <Tabs
                  title="Finance"
                  module="Settings"
                  contents={[
                    {
                      hook: "churnreasons",
                      name: "Churn Reasons",
                      component: ChurnReasons,
                    },
                    {
                      hook: "invoices",
                      name: "Invoices",
                      component: InvoiceSigningMethod,
                    },
                    {
                      hook: "expensescategory",
                      name: "Expense Categories",
                      component: ExpenseCategory,
                    },
                    {
                      hook: "taxrates",
                      name: "Tax Rates",
                      component: TaxRates,
                    },

                    {
                      hook: "accountingcontrols",
                      name: "Accounting Controls",
                      component: AccountingControls,
                    },
                  ]}
                />
              </Suspense>
            </>
          }
        />

        <Route
          path="integrations"
          element={
            <>
              <Suspense fallback={<SimpleLoader />}>
                <Tabs
                  module="Settings"
                  title="Integrations"
                  contents={[
                    {
                      hook: "providers",
                      name: "Providers",
                      component: Providers,
                    },
                    {
                      hook: "quickbooks",
                      name: "QuickBooks",
                      component: QuickBookIntegration,
                    },

                    {
                      hook: "vfd",
                      name: "VFD Integration",
                      component: VfdSettings,
                    },
                  ]}
                />
              </Suspense>
            </>
          }
        />

        <Route
          path="industries"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Industries
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <IndustriesSettings />
              </Suspense>
            </>
          }
        />

        <Route
          path="ticket-types"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Ticket Types
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <TicketTypes />
              </Suspense>
            </>
          }
        />
        {/* <Route
          path="problems-and-resolutions"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Problems And Resoutions
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <ProblemsAndResolutions />
              </Suspense>
            </>
          }
        /> */}
        <Route
          path="kpi-list"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>KPI's</PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <KpiList />
              </Suspense>
            </>
          }
        />
        <Route
          path="sources-list"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Sources List
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <SourcesList />
              </Suspense>
            </>
          }
        />
        <Route
          path="wlp-templates"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                WLP Templates
              </PageTitle>{" "}
              <Suspense fallback={<SimpleLoader />}>
                <WLPTemplates />{" "}
              </Suspense>
            </>
          }
        />
        <Route
          path="settings-roles"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>Roles</PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <PermissionRolesSettings />
              </Suspense>
            </>
          }
        />
        <Route
          path="fixed-asset-class"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>Roles</PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <FixedAssetKlass />
              </Suspense>
            </>
          }
        />
        <Route
          path="resources"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Resources
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <Resources />
              </Suspense>
            </>
          }
        />
        <Route
          path="providers"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Providers
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <Providers />
              </Suspense>
            </>
          }
        />

        <Route
          path="presets-manager"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Presets Manager
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <PresetsManager />
              </Suspense>
            </>
          }
        />

        <Route
          path="triggers"
          element={
            <>
              <PageTitle breadcrumbs={innerRouteBreadCrumbs}>
                Triggers
              </PageTitle>
              <Suspense fallback={<SimpleLoader />}>
                <TriggersIndex />
              </Suspense>
            </>
          }
        />
        <Route index element={<Navigate to="/settings/general" />} />
      </Route>
    </Routes>
  );
};

export default SettingsPage;

//============ THIS IS MODULES'S ROUTES ============
export const SettingsRoutes = () => (
  <>
    <SidebarMenuItem
      to="/settings/triggers"
      title="Triggers"
      icon="arrows-circle"
      fontIcon="bi-person"
    />
    <SidebarMenuItemWithSub
      to="/settings"
      title="Settings"
      fontIcon="bi-sticky"
      icon="gear"
    >
      <SidebarMenuItem
        to="/settings/general"
        title="General"
        icon="setting-2"
        fontIcon="bi-person"
      />
      {/* <SidebarMenuItem
      to="/settings/settings-roles"
      title="Roles Settings test"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}
      {/* <SidebarMenuItem
      to="/settings/company"
      title="Company"
      icon="bank"
      fontIcon="bi-person"
    /> */}
      <SidebarMenuItem
        to="/settings/currency"
        title="Currencies"
        icon="dollar"
        fontIcon="bi-person"
      />
      <SidebarMenuItem
        to="/settings/users"
        title="Users"
        icon="profile-user"
        fontIcon="bi-person"
      />
      {/* <SidebarMenuItem
      to="/settings/permissions-settings"
      title="Permission Settings"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}
      <SidebarMenuItem
        to="/settings/roles"
        title="Roles"
        icon="faceid"
        fontIcon="bi-person"
      />
      {/* <SidebarMenuItemWithSub
      to="/settings/crm"
      title="CRM"
      fontIcon="bi-sticky"
      icon="folder"
    > */}
      {/* <SidebarMenuItem
        to="/settings/industries"
        title="Industries"
        icon="credit-cart"
        fontIcon="bi-person"
      /> */}
      <SidebarMenuItem
        to="/settings/crm"
        title="CRM"
        icon="abstract-34"
        fontIcon="bi-person"
      />
      <SidebarMenuItem
        to="/settings/finance"
        title="Finance"
        icon="wallet"
        fontIcon="bi-person"
      />
      <SidebarMenuItem
        to="/settings/inventory"
        title="Inventory"
        icon="barcode"
        fontIcon="bi-person"
      />{" "}
      <SidebarMenuItem
        to="/settings/workflow"
        title="Workflow"
        icon="wrench"
        fontIcon="bi-person"
      />{" "}
      <SidebarMenuItem
        to="/settings/integrations"
        title="Integrations"
        icon="abstract-26"
        fontIcon="bi-person"
      />
      {/* <SidebarMenuItem
      to="/settings/industries"
      title="Industries"
      icon="ocean"
      fontIcon="bi-person"
    /> */}
      {/* </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItemWithSub
      to="/settings"
      title="Subscriptions"
      fontIcon="bi-person"
      icon="folder"
    > */}
      {/* <SidebarMenuItem
      to="/settings/churn-reasons"
      title="Churn Reasons"
      icon="document"
      fontIcon="bi-person"
    />
    {/* </SidebarMenuItemWithSub> */}
      {/* <CustomerServiceSettingsRoutes />
    <SalesManagementSettingsRoutes />
    <OrderFulFillmentSettingsRoutes />
    <UnitsAndProvidersSettingsRoutes />

    <SidebarMenuItem
      to="/settings/fixed-asset-class"
      title="Fixed Asset Classes"
      icon="barcode"
      fontIcon="bi-person"
    />  */}
      {/* <SidebarMenuItem
      to="/settings/case-types"
      title="Case Types"
      icon="credit-cart"
      fontIcon="bi-person"
    /> */}
      {/* <SidebarMenuItem
      to="/settings/locations"
      title="Product Locations"
      icon="shop"
      fontIcon="bi-person"
    />

    <SidebarMenuItem
      to="/settings/product-categories"
      title="Product Categories"
      icon="lots-shopping"
      fontIcon="bi-person"
    /> */}
      {/* <SidebarMenuItem
      to="/settings/expense-categories"
      title="Expense Categories"
      icon="credit-cart"
      fontIcon="bi-person"
    />

    <SidebarMenuItem
      to="/settings/working-hours"
      title="Working Hours"
      icon="time"
      fontIcon="bi-person"
    />

    <SidebarMenuItem
      to="/settings/holidays"
      title="Holidays"
      icon="calendar"
      fontIcon="bi-person"
    />

    <SidebarMenuItem
      to="/settings/quickbooks-integration"
      title="QuickBookIntegration"
      icon="abstract-26"
      fontIcon="bi-person"
    /> */}
      <SidebarMenuItem
        to="/settings/wlp-templates"
        title="WLP Templates"
        icon="credit-cart"
        fontIcon="bi-person"
      />
      <SidebarMenuItem
        to="/settings/presets-manager"
        title="Presets Manager"
        icon="credit-cart"
        fontIcon="bi-person"
      />
    </SidebarMenuItemWithSub>
  </>
);

const CustomerServiceSettingsRoutes = () => {
  return (
    <>
      {/* <SidebarMenuItemWithSub
      to="/settings"
      title="Customer Service"
      fontIcon="bi-person"
      icon="folder"
    > */}
      <SidebarMenuItem
        to="/settings/ticket-types"
        title="Ticket Types"
        icon="badge"
        fontIcon="bi-person"
      />
      <SidebarMenuItem
        to="/settings/problems-and-resolutions"
        title="Problems & Resolutions"
        icon="tablet-book"
        fontIcon="bi-person"
      />
      <SidebarMenuItem
        to="/settings/kpi-list"
        title="KPI's"
        icon="watch"
        fontIcon="bi-person"
      />
      {/* </SidebarMenuItemWithSub> */}
    </>
  );
};

const SalesManagementSettingsRoutes = () => {
  return (
    // <SidebarMenuItemWithSub
    //   to="/settings"
    //   title="Sales Management"
    //   fontIcon="bi-person"
    //   icon="folder"
    // >
    <SidebarMenuItem
      to="/settings/sources-list"
      title="Sources"
      icon="glass"
      fontIcon="bi-person"
    />
    // </SidebarMenuItemWithSub>
  );
};

const OrderFulFillmentSettingsRoutes = () => {
  return (
    // <SidebarMenuItemWithSub
    //   to="/settings"
    //   title="Order FulFillment"
    //   fontIcon="bi-person"
    //   icon="folder"
    // >
    <SidebarMenuItem
      to="/settings/resources"
      title="Resources"
      icon="data"
      fontIcon="bi-person"
    />
    // </SidebarMenuItemWithSub>
  );
};

const UnitsAndProvidersSettingsRoutes = () => {
  return (
    <>
      {/* <SidebarMenuItemWithSub
      to="/settings"
      title="Units And Providers"
      fontIcon="bi-person"
      icon="folder"
    > */}
      <SidebarMenuItem
        to="/settings/providers"
        title="Providers"
        icon="artificial-intelligence"
        fontIcon="bi-person"
      />
      <SidebarMenuItem
        to="/settings/presets-manager"
        title="Presets Manager"
        icon="subtitle"
        fontIcon="bi-person"
      />
      {/* // </SidebarMenuItemWithSub> */}
    </>
  );
};
