import axios from "axios";
import { BASE_URL } from "../modules/Profile/components/constants";

export async function downloadFile(
  link: string,
  fileName: string
): Promise<void> {
  try {
    const response = await axios.get(`${BASE_URL}${link}`, {
      responseType: "blob",
    });

    // Create a blob URL for the blob data
    const blobUrl = URL.createObjectURL(response.data);

    // Create a link element
    const downloadLink = document.createElement("a");

    // Set the download link attributes
    downloadLink.href = blobUrl;
    downloadLink.download = fileName;

    // Append the link to the document
    document.body.appendChild(downloadLink);

    // Trigger a click event on the link to start the download
    downloadLink.click();

    // Remove the link from the document
    document.body.removeChild(downloadLink);

    // Clean up the blob URL
    URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
}

// Example usage
// const fileLink = 'https://example.com/path/to/file.pdf';
// const fileName = 'downloaded_file.pdf';

// downloadFile(fileLink, fileName);
