import { RootState } from "../store";

/**
 *
 * @param branch this can be a branch value like "general" or etc
 * @returns
 */
export const getSettings = (branch?: string) => (state: RootState) =>
  branch ? state?.settings?.data[branch] : state?.settings?.data;

export const getSettingsReactive = (state: RootState) => state?.settings?.data;
export const getEditedSettings = (state: RootState) => state.settings.updated;
export const isSettingBranchDirty = (branch: string) => (state: RootState) =>
  state.settings.updated[branch];

export const getSettingsLoadingState = (state: RootState) =>
  state.settings.isLoading;

export const TYPE_OF_SETTINGS = {
  general: "general",
  quickbooks_integration: "quickbooks_integration",
  totalvfd_integration: "totalvfd_integration",
};
